<template>
  <div>
    <a-button
      @click="formEditorVisible = true"
    >
      <template #icon>
        <i class="fa fa-cog" />
      </template>
    </a-button>

    <FormEditorDialog
      v-if="!!objectData"
      :visible="formEditorVisible"
      :representName="objectData.systemName"
      :fields="fields"
      @close="formEditorVisible = false"
      @save="handleChange"
    />
  </div>
</template>
<script>
import FormEditorDialog from "@/components/form-generation/designer/sp-form-designer-dialog.vue";
export default {
  name: "sp-represent-form-editor",
  components: {
    FormEditorDialog,
  },
  emits: ["update:value"],
  props: {
    value: {
      type: String,
      default: null,
    },
    objectData: { type: Object, required: true },
  },
  data() { 
    return {
      formEditorVisible: false
    };
  },
  computed: { 
    fields() {
      return JSON.parse(this.value);
    }
  },
  methods: {
    handleChange(fields) {
      this.$emit("update:value", JSON.stringify(fields));
    }
  }
};
</script>