<template>
  <div>
    <grid
      v-if="editAllow"
      :patchProps="patchProps"
      :filters="filters"
      :representService="gridRepresentService"
      :hiddenFields="['representId']"
    />
    <div
      v-else
    >
      Доступно после сохранения
    </div>
  </div>
</template>
<script>
import {toRefs, ref, onMounted, watch, defineAsyncComponent} from "vue";
import RepresentService from "@/services/represent-service";

export default {
  name: "sp-represent-grid-filters",
  components:{
    grid: defineAsyncComponent(() =>
      import("@/components/grid.vue")),
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    objectData: { type: Object, required: true },
  },
  emits: ["update"],
  setup(props, { emit }) {
    const { value, objectData } = toRefs(props);

    const gridRepresentService = new RepresentService("GridFilerItem")

    const patchProps = ref({})
    const filters = ref({})
    const editAllow = ref(!!objectData.value.id)

    watch(objectData, () => {
      if(editAllow.value){
        patchProps.value = {representId: objectData.value.id}
        filters.value = [{field: "Represent.Id", equal: objectData.value.id}]
      }
    });

    const handleChange = (val) => {
      let changedValue = ""

      emit("update:value", JSON.stringify(changedValue));
    };

    onMounted(async () => {
      if(editAllow.value){
        patchProps.value = {representId: objectData.value.id}
        filters.value = [{field: "Represent.Id", equal: objectData.value.id}]
      }
    });

    return {
      patchProps,
      filters,
      gridRepresentService,
      editAllow,
      handleChange,
    };
  },
};
</script>