<template>
  <a-row type="flex" v-if="!!objectData?.id">
    <a-space>
      <a-button class="mb-2" @click="exportJson">
        Экспорт
      </a-button>
      <a-button class="mb-2" @click="showImportJson = true">
        Импорт
      </a-button>
    </a-space>
    <a-modal 
      v-model:visible="showJson" 
      title="JSON"
      @ok="showJson = false"
    >
      <a-textarea
        readonly
        v-model:value="json"
        :auto-size="{ minRows: 5 }"
      />
    </a-modal>
    <a-modal 
      v-model:visible="showImportJson" 
      title="Импорт"
      @ok="importJson"
    >
      <a-textarea
        v-model:value="jsonToImport"
        :auto-size="{ minRows: 5 }"
      />
    </a-modal>
  </a-row>
  <a-row type="flex" v-if="!!objectData?.id">
    <a-col flex="50%">
      <div class="card m-2">
        <div class="card-header">Доступные поля</div>
        <div class="card-body">
          <draggable
            class="draggable-list"
            :list="availableList"
            group="people"
            itemKey="key"
          >
            <template #item="{ element }">
              <a-button block style="cursor: move;" class="mb-2">
                {{ element.systemName }}
              </a-button>
            </template>
          </draggable>
        </div>
      </div>
    </a-col>
    <a-col flex="50%">
      <div class="card m-2">
        <div class="card-header">Выбранные поля</div>
        <div class="card-body">
          <draggable
            class="draggable-list"
            :list="selectedList"
            group="people"
            @change="handleChange"
            itemKey="key"
          >
            <template #item="{ element }">
              <a-button block style="cursor: move;" class="mb-2">
                {{ element.systemName }}
              </a-button>
            </template>
          </draggable>
        </div>
      </div>
    </a-col>
  </a-row>
  <div v-else>
    Доступно после сохранения
  </div>
</template>
<script>
import Draggable from "vuedraggable";
import CrudService from "@/services/crud-service";
import { error } from "@/helpers/notifications";
export default {
  name: "sp-represent-grid-columns",
  components: {
    Draggable,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    objectData: { type: Object, required: true },
  },
  emits: ["update:value"],
  data() {
    return {
      availableList: [],
      selectedList: [],
      entityFields: [],
      json: null,
      jsonToImport: null,
      showJson: false,
      showImportJson: false,
    };
  },
  methods: {
    handleChange() {
      let changedValue = this.selectedList
        .map(item => { 
          let field = this.entityFields.find(x => item.value === x.id);
          return {
            dataIndex: field.listViewSystemName,
            title: field.customName,
            type: field.listViewComponent
          }
        });

      this.$emit("update:value", JSON.stringify(changedValue));
    },
    update() {
      this.initByValue(JSON.parse(this.value));
    },
    initByValue(value) {
      let all = this.entityFields
        .filter(x => x.listViewComponent != null)
        .map(option => ({
          key: option.id,
          systemName: option.systemName,
          //label: option.customName,
          value: option.id,
        }));
      if (value) {
        let selectedIndexes = value.map(x => x.dataIndex.toLowerCase());
        this.selectedList = selectedIndexes.map(x => all.find(f => f.systemName.toLowerCase() === x)).filter(x => x != null);
        this.availableList = all.filter(x => !this.selectedList.some(s => x.key === s.key));
      }
      else this.availableList = all;
    },
    importJson(){
      try {
        var parsed = JSON.parse(this.jsonToImport);
        var newValue = parsed
          .map(item => { 
            let field = this.entityFields.find(x => item === x.listViewSystemName);
            return {
              dataIndex: field.listViewSystemName,
              title: field.customName,
              type: field.listViewComponent
            }
          });
        this.initByValue(newValue);
        this.$emit("update:value", JSON.stringify(newValue));
        this.showImportJson = false;
        this.jsonToImport = null;
      }
      catch(e) {
        error("Не удалось импортировать");
        throw e;
      }
    },
    exportJson() {
      var indices = JSON.parse(this.value).map(x => x.dataIndex);
      this.json = JSON.stringify(indices, null, 2); 
      this.showJson = true;
    }
  },
  watch: {
    value: {
      handler() {
        this.update();
      }
    }
  },
  async mounted() {
    let filters = { Items: [{ field: "EntityId", equal: this.objectData.entityId }] };
    this.entityFields = (await new CrudService("EntityField").gridData(filters)).data;

    this.update();
  },
};
</script>

<style scoped>
  .draggable-list {
    overflow: auto;
    overflow-x: hidden;
    max-height: 400px;
  }
</style>