<template>
  <a-modal 
    title="Конструктор формы"
    v-model:visible="innerVisible"
    :confirm-loading="loading"
    width="90%"
    @ok="onOk"
    @cancel="onCancel"
  >
    <a-row type="flex">
      <a-col flex="220px">
        <div class="card m-1">
          <div class="card-header">Элементы</div>
          <div class="card-body">
            <WidgetList />
          </div>
        </div>
      </a-col>
      <a-col flex="auto">
        <div class="card m-1">
          <div class="card-header">Форма</div>
          <div class="card-body">
            <widget-form />
          </div>
          <div class="card-footer">
            <a-space>
              <a-button 
                @click="json = JSON.stringify(widgetList, null, 2);showJson = true;"
              >
                Export JSON
              </a-button>

              <a-button
                @click="jsonToImport = null;showImportJson = true;"
              >
                Import JSON
              </a-button>
              <a-button
                @click="showPreview = true;"
              >
                Form preview
              </a-button>
            </a-space>
            <a-modal v-model:visible="showJson" title="JSON">
              <pre>{{ json }}</pre>
            </a-modal>
            <a-modal 
              v-model:visible="showImportJson" 
              title="Import JSON"
              @ok="importJson"
            >
              <a-textarea
                v-model:value="jsonToImport"
                :auto-size="{ minRows: 5 }"
              />
            </a-modal>
            <a-modal v-model:visible="showPreview" title="Preview">
              <FormGenerator 
                v-if="showPreview"
                :items="widgetList" 
              />
            </a-modal>
          </div>
        </div>
      </a-col>
      <a-col flex="220px">
        <div class="card m-1">
          <div class="card-header">Свойства</div>
          <div class="card-body">
            <widget-config v-if="selectedWidget != null" />
          </div>
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import WidgetList from "./sp-form-designer-item-list.vue";
import WidgetForm from "./sp-form-designer-item-form.vue";
import WidgetConfig from "./sp-form-designer-item-config.vue";
import FormGenerator from "../sp-form-generator.vue";
import useSharedState from "./js/shared-state";
import api from "@/api";

export default {
  name: "sp-form-designer-dialog",
  components: {
    WidgetList,
    WidgetForm,
    WidgetConfig,
    FormGenerator,
  },
  props: {
    visible: { type: Boolean, required: true },
    representName: { type: String, required: true },
    fields: { type: Object, default: () => ({}) },
  },
  emits: ["close", "save"],
  data() {
    return {
      innerVisible: false,
      loading: false,

      json: null,
      jsonToImport: null,
      showJson: false,
      showImportJson: false,
      showPreview: false,
    }
  },
  setup() {
    return { ...useSharedState() };
  },
  watch: {
    visible: {
      immediate: true,
      async handler(visible) {
        if (visible != this.innerVisible)
          this.innerVisible = visible;
        if (visible)
          await this.init();
      }
    },
    async representName() { 
      await this.init();
    }
  },
  methods: {
    async init() {
      this.selectedWidget = null;
      let entityFields = await api.entityConfig.representEntityFields(this.representName);
      this.setWidgetList(this.fields);
      this.setFieldList(entityFields);
    },
    async onOk() {
      this.$emit("close");
      this.$emit("save", this.widgetList);
    },
    onCancel() {
      this.$emit("close");
    },
    async importJson(){
      this.showImportJson = false
      this.setWidgetList(JSON.parse(this.jsonToImport));
      this.$emit("save", this.widgetList);      
    }
  }
}
</script>

<style>

</style>