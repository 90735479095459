<template>
  <a-form 
    ref="form" 
    :model="state"
    :rules="validationRules"
    layout="vertical"
    v-bind="formProps"
  >
    <a-form-item 
      v-for="item in items"
      :key="item.name"
      :name="item.name" 
      :label="item.label"
    >
      <a-input-number
        v-if="item.type === itemTypes.number"
        v-model:value="state[item.name]"
        v-bind="item.options"
        @change="addChange(item)"
      />
      <a-input 
        v-else-if="item.type === itemTypes.string"
        v-model:value="state[item.name]"
        v-bind="item.options"
        @change="addChange(item)"
      />
      <a-textarea 
        v-else-if="item.type === itemTypes.text"
        v-model:value="state[item.name]"
        v-bind="item.options"
        @change="addChange(item)"
      />
      <a-select
        v-else-if="item.type === itemTypes.reference"
        v-model:value="state[item.name]"
        v-bind="item.options"
        @change="addChange(item)"
      />
      <a-date-picker
        v-else-if="item.type === itemTypes.date"
        v-model:value="state[item.name]"
        v-bind="item.options"
        @change="addChange(item)"
      />
      <a-switch
        v-else-if="item.type === itemTypes.boolean"
        v-model:checked="state[item.name]"
        v-bind="item.options"
        @change="addChange(item)"
      />
      <FileInput
        v-else-if="item.type === itemTypes.files"
        v-model:value="state[item.name]"
        bucketName="SampleProgram" 
        @update:value="addChange(item)"
      />
      <RepresentGridColumns
        v-else-if="item.type === itemTypes.representGridColumns"
        v-model:value="state[item.name]"
        :objectData="objectData"
        @update:value="addChange(item)"
      />
      <RepresentGridFilters
        v-else-if="item.type === itemTypes.representGridFilters"
        v-model:value="state[item.name]"
        :objectData="objectData"
        @update:value="addChange(item)"
      />
      <RepresentFormEditor
        v-else-if="item.type === itemTypes.representFormEditor"
        v-model:value="state[item.name]"
        :objectData="objectData"
        @update:value="addChange(item)"
      />
      <UserRoleType
        v-else-if="item.type === itemTypes.userRoleType"
        v-model:value="state[item.name]"
        @update:value="addChange(item)"
      />
      <div v-else class="font-weight-bold">
        Component unknown
      </div>
    </a-form-item>
  </a-form>
</template>

<script>
import { itemTypes, representTypes } from "@/helpers/form-generator";
import FileInput from "@/components/editors/sp-file-input";
import RepresentGridColumns from "@/components/editors/sp-represent-grid-columns";
import RepresentFormEditor from "@/components/editors/sp-represent-form-editor";
import RepresentGridFilters from "@/components/editors/sp-represent-grid-filters";
import UserRoleType from "@/components/editors/sp-user-role-type.vue";
import moment from "moment";
import { pick } from "lodash";
export default {
  name: "sp-simple-form-generator",
  components: {
    FileInput,
    RepresentGridColumns,
    RepresentFormEditor,
    RepresentGridFilters,
    UserRoleType,
  },
  props: {
    items: { type: Array, required: true },
    represent: { type: Object },
    objectData: { type: Object },
    formProps: { type: Object, default: () => ({}) },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      state: {},
      validationRules: {},
      itemTypes: itemTypes,
      changedItems: []
    }
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        this.state = {};
        this.validationRules = {};
        for (const item of items) {
          if (item.type === 'date' && item.value)
            this.state[item.name] = moment(item.value);
          else this.state[item.name] = item.value;
          if (item.validationRules?.length > 0)
            this.validationRules[item.name] = item.validationRules;
          
          if (this.represent) {
            let rt = representTypes[this.represent.systemName];
            if(rt){
              let resolvedType = rt[item.name];
              if(resolvedType)
                item.type = resolvedType;
            }
          }

          if (this.readonly) {
            item.options.disabled = true;
          }
        }
      }
    }
  },
  computed: {
    changedState() {
      return pick(this.state, this.changedItems);
    },
  },
  methods: {    
    async validate() {
      await this.$refs.form.validate();
    },
    clear() {
      this.clearChanges();
      for (const item of this.items)
        this.state[item.name] = null;
    },
    clearChanges() {
      this.changedItems = [];
    },
    addChange(item) {
      if (!this.changedItems.includes(item.name))
        this.changedItems.push(item.name);
    }
  },
  mounted() {
    this.$emit("initialized", this);
  }
}
</script>